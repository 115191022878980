.background {
    background: #242d30;
    position: relative;
    min-height: 2000px;
    height: auto;
    
}
ul {
    list-style: none;
    padding: 0;
    margin:0;
}
.container {
    background-position: center top;
    background-size: contain;
    background-size:cover;
    width: 100%;
    height: auto;
    padding-bottom: 40px;
    left:0;
    background-repeat: no-repeat;
    margin: 0 auto;
}
.container h2 {
    padding-top: 2vh;
    color:#fff;
    text-align: center;
    font-family: var(--dela);
    font-weight: 400;
    font-size:clamp(28px, 5vw, 40px);
}
.content {
    max-width: 1900px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 300px;
}
.stone_list {
    display: flex;
    flex-direction: column;
    gap: 166px;
    margin: 0 auto;
    width: auto;
    align-items: center;
    padding: 0 10%;
    margin-top: 76px;
    position: relative;
    z-index: 30;
    li {
        padding: 5px 0;
    }
}
.star_list {
    margin-left: 5px;
    text-align: start;
    li {
        list-style-image: url('../../../public/assets/images/star.svg');
        margin-bottom: 10px;
    }
}
.river_castle {
    position: absolute;
    bottom: -177px;
    width: 100%;
    z-index: 0;
    right: -8em;
    img {
        width: 1800px;
        margin: 0 auto;
    }
}
.stone_list_item {
    display: flex;
    background: #fff;
    border-radius: 40px;
    box-shadow: 12px 12px 0 #2A3236;
    max-width: 800px;
    gap:5%;
    align-items: center;
    span {
        word-wrap: break-word;
        font-family: var(--dela);
        color:#FFE350;
        font-weight: 400;
        text-shadow: 2px 2px 0 #7E4C00;
        font-size: clamp(48px, 7vw, 70px);
        -webkit-text-stroke: 2px #000000;
          text-stroke: 2x #000000;
          text-shadow: 4px 2px 0 #7e4c00;
          text-align: end;
        border-right: 1px solid #242D3030;
        padding: 2%;
    }
    p, li {
        font-family: var(--podkova);
        font-size: clamp(20px, 5vw, 30px);
        font-weight: 800;
    }
}

.stone_list_item:nth-child(1),
.stone_list_item:nth-child(3)
{
    margin-left: 120px;
}
.stone_list_item:nth-child(2),
.stone_list_item:nth-child(4)
{
    margin-right: 120px;
}
@media (max-width:850px) {
    .stone_list_item {
        flex-direction: column;
        margin-left: 0!important;
        margin-right: 0!important;
        margin-top: 0;
        width: 100%;
        span {
            border: none;
            border-bottom: 1px solid #242D3030;
            margin-bottom: 5%;
            text-wrap: nowrap;
            text-align: center;
        }
    }
    .stone_list {
        padding: 0;
        margin: 0 3% !important;
    }
}
@media (max-width:600px) {
    .stone_list {
        margin: 0 3% !important;
        margin-top: 56px !important;
        gap: 45px;
    }
}
.castele {
    display: none;
}
@media (max-width:1800px) {
    .river_castle {
        right: -1em;
    }
}
@media (max-width:1600px) {
    .river_castle {
        right: 4em;
    }
}
@media (max-width:1600px) {
    .river_castle {
        right: 12em;
    }
}
@media (max-width:1000px) {
    .river_castle {
        right: 20em;
    }
}
@media (max-width:850px) {
    .river_castle {
        right: 100%;
    }
    .river {
        display: none;
    }
    .content {
        padding-bottom: 550px;
    }
    .castele {
        display: block;
    }
    .stone_list_item {
        padding: 5px 40px!important;
    }

}
@media (max-width:700px) {
    .river_castle {
        right: 130%;
    }
    .background {
        min-height: 0!important;
    }
}
@media (max-width:600px) {
    .river_castle {
        right: 0;
        img {
            margin: 0 auto;
            transform: translateX(-55%);
            width: 1600px;
        }   
    }
}
@media (max-width:400px) {
    .river_castle {
        right: 0;
        img {
            margin: 0 auto;
            transform: translateX(-57%);
            width: 1600px;
        }   
    }
}