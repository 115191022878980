@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,700;1,700&family=Comfortaa:wght@300&family=Inter:wght@100..900&family=Madimi+One&family=Montserrat:wght@100..900&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,700;1,700&family=Comfortaa:wght@300&family=Dela+Gothic+One&family=Inter:wght@100..900&family=Madimi+One&family=Montserrat:wght@100..900&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu:wght@400;500;700&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: var(--podkova);
}
:root {
    --podkova:"Podkova", serif;
    --dela:"Dela Gothic One", sans-serif;
    --dark:#000939;
    --orange:#FB5D04;
}
.main_container {
    overflow-x: hidden;
}
