.section_background {
  min-height: 900px;
  background: #c98a49;
  position: relative;
  background-size: cover;
  width: 100%;
  background-color: #C98A49;
  z-index: 1;
  min-height: 900px;
  padding-top: 50px;
}
.container {
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 900px;
  }
.section_background h2 {
  color:#fff;
  font-family: var(--dela);
  text-align: center;
  font-size: clamp(28px,6vw,40px);
  margin-bottom: 60px;
  padding-top: 20px;
  text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.1450980392);
  position: relative;
  
  z-index: 30;
  }
  
  .slider_back {
    position: absolute;
    bottom: 0px;
    left: 0px;
   transform: translateX(-25%);
   z-index: 0;
  }

.slider_back {
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translateX(-25%);
  z-index: 2;
}

.section_background {
  min-height: calc(100% + 800px);
  background: #c98a49;
  position: relative;
  z-index: 1;

}

.container {
  position: relative;
  width:100vw; // Примерная максимальная ширина для контейнера
  margin: 0 auto;
  overflow: visible;
}

.slider_back {
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translateX(-25%);
  z-index: 0;
}
.custom_prev_button, .custom_next_button {
  position: absolute;
  top: 35%;
  transform: translateY(-5%);
  z-index: 4;
  
}

.custom_prev_button {
  left: 10px;
  cursor: url('../../../public/assets/images/Cursor.svg'), auto;
}

.custom_next_button {
  right: 10px;
  cursor: url('../../../public/assets/images/Cursor.svg'), auto;
}
.swiper {
  max-width: 100%;
  margin: 0 auto;
}

.slide_item {
  max-width: 300px;
  width: 100%;
}

.button_container {
  position: absolute;
  z-index: 30;
  bottom: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
}


@media (max-width: 600px) {
  .swiper {
    display: flex;
    gap: 10px;
  }
  .navigation {
    display: none;
  }
  .slide_item {
    max-width: none;
    width: 100%;
  }
  .section_background {
    padding-top: 0;
    h2 {
      width: 70%;
      margin: 0 auto;
      padding: 0 0 58px!important;

    }
  }
}

@media (min-width: 601px) and (max-width: 1023px) {
  .swiper {
    gap: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .swiper {
    gap: 30px;
  }
}

@media (min-width: 1400px) {
  .swiper {
    gap: 40px;
  }
}
.river_castle {
  position: absolute;
  top: 0;
  left:30.36em;
  right: none;
  z-index: 1;
}


@media (max-width:1800px) and (min-width:1600px){
  .river_castle {
    left: 23.36em;
  }
}
@media (max-width:1600px) {
  .river_castle {
    left: 10.36em;
  }
}
@media (max-width:1000px) {
  .river_castle {
    left: 2.36em;
  }
}
@media (max-width:850px) {
  .river_castle {
    display: none;
  }
  .container {
    padding-left:20px;
  }
  .section_background {
    width: 200vw;
    transform: translateX(-25%);
  }
  .card_container {
    justify-content: start!important;
    overflow: auto;
  }

}
.card_container {
  display: flex;
  justify-content: center;
  gap:30px;
}
