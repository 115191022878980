.background {
    
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    max-width: 1900px;
}
.section_background {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--dela);
    background: #2270cc;
    max-height: 900px;
}
.fishs {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    margin: 0 auto;
    max-width: 1900px;
}
.water_header {
    padding: 80px 0 46px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: clamp(30px, 5vw, 40px);
    text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.1450980392);
    text-wrap: nowrap;
}
.line {
    position: absolute;
    top:-2vw;
    left:50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1400px;
}
.water_cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    max-width: 920px;
    margin: 0 auto;
    gap:32px;
    
}
.water_card {
    display: flex;
    color:#fff;
    max-width: 500px;
    border:2px solid #fff;
    border-width: 1px 3.5px 3.5px 1px;
    border-radius: 50px;
    border-top-left-radius: 10px;
    padding: 24px 32px 32px;
    gap: 32px;
    span {
        font-size: clamp(59px, 15vw, 100px);
    }
    p {
        font-size: clamp(22px, 5vw, 32px);
        font-family: var(--podkova);
        font-weight: 800;
    }
}
.water_card:nth-child(2) {
    border-radius: 50px;
}
.water_card:nth-child(3) {
    border-radius: 50px;
    border-bottom-left-radius: 10px;
}
.water_card:nth-child(4) {
    border-radius: 50px;
    border-bottom-right-radius: 10px;
}
.fishs img{
    position: absolute;
}
.fishs img:nth-child(1) {
    top:34%;
    right:9%;
    animation: bluefishAnim 6s ease-in-out infinite;
}
.fishs img:nth-child(2) {
    top:10%;
    left:10%;
    animation: greenfishAnim 6s ease-in-out infinite;
}
.fishs img:nth-child(3) {
    bottom:8%;
    left:30%;
    animation: yellowfishAnim 6s ease-in-out infinite;
}
.fishs img:nth-child(4) {
    bottom:3%;
    right:30%;
    animation: yellowfishAnim 6s ease-in-out infinite;
}
@media (max-width:900px) {
    .background {
        height: 1000px!important;
    }
}
@media (max-width:800px) {
    .water_cards {
        grid-template-columns: 1fr;
        gap: 17px;
    }
    .water_card {
        padding: 24px 32px 22px;
    }
    .section_background {
        max-height: 1125px;
    }
}
@media (max-width:1500px) {
    .fishs img:nth-child(1) {
        top:30%;
        right:-16%;
    }
    .fishs img:nth-child(2) {
        top:10%;
        left:-5%;
    }
    .fishs img:nth-child(3) {
        bottom:5%;
        left:22%;
    }
    .fishs img:nth-child(4) {
        bottom:3%;
        right:27%;
    }
}

@media (max-width:1050px) {
    .fishs img:nth-child(1) {
        visibility: hidden;
    }
    .fishs img:nth-child(2) {
        visibility: hidden;
    }
    .fishs img:nth-child(3) {
        visibility: hidden;
    }
    .fishs img:nth-child(4) {
        visibility: hidden;
    }
}
@keyframes greenfishAnim {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(31px, 21px);
    }
    100% {
        transform: translate(0 0);
    }
}
@keyframes yellowfishAnim {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(36px, 12px);
    }
    100% {
        transform: translate(0 0);
    }
}
@keyframes bluefishAnim {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(30px, 5px);
    }
    100% {
        transform: translate(0 0);
    }
}
@media (max-width:600px) {
    .water_header {
        padding: 65px 0 40px;
    }
}