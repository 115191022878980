.start_questing_link {
    background: #FB5D04;
    padding: 0 40px;
    font-weight: 400;
    font-size: clamp(24px, 2vw, 40px);
    line-height: 300%;
    letter-spacing: 0.03em;
    text-align: center;
    color: #fff;
    border: 2px solid #000;
    border-radius: 82px;
    box-shadow: 5px 4px 0 0 #000;
    text-decoration: none;
    transition: all 0.2s linear;
    font-family: "Dela Gothic One", sans-serif;
  }
  .start_questing_link:hover {
    background: #FB7304;
    cursor: url('../../../public/assets/images/Cursor.svg'), auto;
  }
  .start_questing_link:click {
    background: #FB5D04;
  }