@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,700;1,700&family=Comfortaa:wght@300&family=Inter:wght@100..900&family=Madimi+One&family=Montserrat:wght@100..900&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,700;1,700&family=Comfortaa:wght@300&family=Dela+Gothic+One&family=Inter:wght@100..900&family=Madimi+One&family=Montserrat:wght@100..900&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,700;1,700&family=Comfortaa:wght@300&family=Dela+Gothic+One&family=Inter:wght@100..900&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&family=Ubuntu:wght@400;500;700&display=swap');

$font-podkova: "Podkova", serif;
$font-dela: "Dela Gothic One", sans-serif;
$color-dark: #000939;
$color-orange: #FB5D04;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: $font-podkova;
}

:root {
  --podkova: $font-podkova;
  --dela: $font-dela;
  --plus:"Plus Jakarta Sans", sans-serif;
  --dark: $color-dark;
  --orange: $color-orange;
}

.main__screen {
  position: relative;
  background: #65D2EF;
  width: 100%;
  height: 100vh;
  max-height: 900px;

  .back_tree {
    left: 400px;
  }

  .back_grass {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 10;
  }

  .container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .main__screen_content {
    padding-top: 3.2vh;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    height: 100%;
    width: 100%;

    p {
      font-weight: 800;
      font-size: clamp(24px, 2vw, 36px) !important;
      line-height: 122%;
      text-align: center;
      color: #000939;
      margin: 2vh 0 3vh;
      width: 60%;
    }

    span {
      font-weight: 1000;
      font-size: clamp(38px, 5.9vw, 105px);
      line-height: 122%;
      text-align: center;
      font-family: "Dela Gothic One", sans-serif;
      color: #fed74a;
      -webkit-text-stroke: 0.3px #000000;
      text-stroke: 0.3px #000000;
      margin-top: -1.7vw;
      text-shadow: 4px 2px 0 #7e4c00;
      letter-spacing: -3px;
    }

    
    
  }

  .main__screen_header {
    text-transform: uppercase;
  }

  .main__screen_body {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    height: 100%;
    width: 100%;

    img {
      width: 15vw;
      max-width: 250px;
    }
  }

  .sun {
    position: absolute;
    top: -100px;
    left: 500px;
    z-index: 5;
  }

  .forest,
  .clouds {
    bottom: 10px;
    position: absolute;
    left: 0;
    transform: translateX(-25%);
  }

  .forest {
    z-index: 4;
  }

  .clouds {
    z-index: 2;
    transform: translateX(-30%);
  }

  .amin_clouds {
    position: absolute;
    top: 5vh;
    left: -20vw;
    z-index: 5;
    width: clamp(898px, 82vw, 1700px);
    animation: animClouds 12s ease-in-out alternate infinite;
  }

  .mountain {
    position: absolute;
    bottom: 10px;
    left: 0;
    transform: translateX(-25%);
    z-index: 0;
  }
}

@keyframes animClouds {
  0% {
    left: --40vw;
  }
  100% {
    left: 0;
  }
}
@media (max-width:1400px) {
  .main__screen {
    max-height: 810px;
    min-height: 700px;
  }
  .main__screen_body {
    padding-top: 3.5vh;;
  }
}
@media (max-width:600px) {
  .main__screen {
    height: 810px;
  }
  .main__screen_body img {
    width: 170px!important;
  }
  .main__screen_content {
    padding-top: 3.2vh;
    span {
      margin-top: -3.7vw!important;
      letter-spacing: 0!important;
    }
    p {
      margin: 3.2vh 0 7vh !important;
      width: 100%!important;
    }
  }
}