.footer {
    background: #F36000;
    display: flex;
    flex-direction: column;
    gap:80px;
    padding: 90px 0;
    align-items: center;
    position: relative;
    z-index: 300;
}
.footer h2 {
    font-family: var(--dela);
    color:#fff;
    font-weight: 400;
    font-size: clamp(28px, 5vw, 40px);
}
.footer_images {
    display: flex;
    gap: 28px;
    min-width: 80px;
    img {
        width: 20vw;
        max-width: 112px;
    }
    :hover {
        cursor: url('../../../public/assets/images/Cursor.svg'), auto;
    }
}
.line {
    position: absolute;
    top: -30px;
    margin-bottom: -50px;
}
@media (max-width:600px) {
    .footer_images {
        margin-top: -40px;
    }
}