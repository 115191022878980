
  .slide_item_content {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    height: 405px;
  }
  .slide_img {
    width: 100%;
  
  }
  .slide_img img {
    height: 150px;
    width: 102%;
    object-fit: cover;
    object-position: 0 30%;
  }
  .slide_body {
    padding: 12px;
  }
  .slide_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .creater {
    display: flex;
    align-items: center;
    gap:6px;
  }
  .creater img {
    border-radius: 4px;
    width: 35px;
  }
  
  .verify {
    margin-top: 7px;
   
  }
  .verify img {
    width: 16px;
    height: 16px;
  }
  .status {
    background: #ECEFF3;
    padding: 5px 20px;
    border-radius: 100px;
    border: 0.65px solid #C1C7D0;
    color:#A5AABE;
    font-size: 12px;
  }
  .slide_body h3 {
    margin-bottom: 12px;
  }
  .button {
    height: 52px;
    width: 100%;
    background: #20B65E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color:#fff;
    margin-top: 12px;
    cursor: pointer;
    text-decoration: none;
  }
.body_container {
  display: flex;
  flex-direction: column;
  height: 190px;
  justify-content: space-between;
}