.frame_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    margin: 20px auto;
    overflow: hidden;
    position: absolute!important;
    z-index: 20!important;
    bottom:-20px;
    right: 0%;
  }
  
  .frame {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  